<template>
  <list-template :total="total" :loading="loading" :search-config="searchConfig" :table-data="tableData.list" :table-config="tableConfig" :current-page="page"
    @onSearch="onSearch" @onReset="onReset" @onChangePage="changeCurrentPage" @onHandle="tableHandle">
  </list-template>
</template>

<script>
import {mapState} from 'vuex'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data () {
    return {
      loading:true,
      dataTable: { },
      tableConfig: [
        {
          prop: 'name',
          label: '测评名称'
        },
        {
          prop: 'student',
          label: '学生姓名'
        },
        {
          prop: 'student_no',
          label: '学号',
          width: 150
        },
        {
          prop: 'score',
          label: '总分'
        },
        {
          prop: 'average',
          label: '总均分'
        },
        {
          prop: 'positive',
          label: '阳性项目数'
        },
        {
          prop: 'class',
          label: '行政班'
        },
        {
          prop: 'grade',
          label: '年级'
        },
        {
          prop: 'school',
          label: '校区',
          width: 150
        },
        {
          prop: 'state_text',
          label: '提交状态'
        },
        {
          label: '操作',
          handle: true,
          width: '80',
          buttons: [{ type:"view", text:"查看" }]
        }
      ],
      searchConfig: [
        {
          prop: "evaluation_name",
          placeholder: "搜索测评名称"
        },
        {
          prop: "student_name",
          placeholder: "搜索学生姓名"
        },
        {
          prop: "score",
          placeholder: "查询总分最低分"
        },
        {
          prop: "positive",
          placeholder: "查询阳性项目数"
        },
        {
          prop: 'school_id',
          placeholder: '筛选校区',
          tag: 'select',
          label: 'name',
          value: 'id',
          change: (val,search) => {
            if(search.grade_id) {
              search.grade_id = ''
            }
            if(search.class_id) {
              search.class_id = ''
            }
            this.searchConfig[5].options = []
            this.searchConfig[6].options = []
            this.$_axios2.get('api/evaluation/result/get-range?school_id=' + val).then(res=>{
              this.searchConfig[5].options = res.data.data.grade
              this.searchConfig[6].options = res.data.data.class
            })
          },
          options: []
        },
        {
          prop: 'grade_id',
          placeholder: '筛选年级',
          tag: 'select',
          label: 'name',
          value: 'id',
          change: (val,search) => {
            if(search.class_id) {
              search.class_id = ''
            }
            this.searchConfig[6].options = []
            this.$_axios2.get('api/evaluation/result/get-range?grade_id=' + val + '&school_id=' + this.dataTable.school_id).then(res => {
              this.searchConfig[6].options = res.data.data.class
            })
          },
          options: []
        },
        {
          prop: 'class_id',
          placeholder: '筛选行政班',
          tag: 'select',
          label: 'name',
          value: 'id',
          options: []
        },
        {
          prop: 'state',
          placeholder: '筛选提交状态',
          tag: 'select',
          value: 'id',
          label: 'name',
          options: []
        },
        {
          prop: 'average',
          placeholder: '筛选总均分分段',
          tag: 'select',
          options: []
        },
      ],
      tableData: [],
      total: 0,
    }
  },
  mounted () {
    this.getPush()
  },
  activated () {
    this.getPush()
  },
  computed: {
    ...mapState(['page'])
  },
  methods: {
    // 分页被更改
    changeCurrentPage (e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    /**
     * 获取数据
     */
    getData () {
      this.loading = true;
      this.$_axios2.get('api/evaluation/result', { params: {page:this.page, ...this.dataTable } }).then(res => {
        if (res.data.data.list) {
          this.tableData = res.data.data
          this.total = res.data.data.page.total
        }else{
          this.tableData = []
          this.total = 0
        }
      }).finally(()=>this.loading=false)
    },
    // 搜索按钮被点击
    onSearch (val) {
      this.$store.commit('setPage', 1)
      this.dataTable = val
      this.getData()
    },
    onReset () {
      this.$store.commit('setPage', 1)
      this.dataTable = {}
      this.getData()
      this.commonInterface()
    },
    // 表格后面操作被点击
    tableHandle (row) {
      this.$router.push('./examine?id=' + row.id)
    },
    commonInterface () {
      this.$_axios2.get('api/evaluation/result/get-range').then(res=>{
				if (res.data.status === 0 ){
          this.searchConfig[4].options = res.data.data.school
          this.searchConfig[5].options = res.data.data.grade
          this.searchConfig[6].options = res.data.data.class
				}
      })
    },
    getPush(){
      if (this.$route.query.id){
        this.dataTable = { crisis: 1 }
        setTimeout(()=>{
          this.getData()
        },10)
      }else{
        this.getData()
      }
    },
  },
  created () {
    this.$_axios2.get('api/evaluation/result/get-search').then(res => {
      this.searchConfig[7].options = res.data.data.state
      const change = (item) => {
        const val = item.toString().replace(/,/g, '-')
        return { label: val, value: val }
      }
      res.data.data.average.forEach(item => {
        this.searchConfig[8].options.push(change(item))
      })
    })
    this.commonInterface()
  },
}
</script>

<style scoped lang="scss"></style>
